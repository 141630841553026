import { openLoginView, removePrecedingSlash } from '@framework/utils/app-util'
import { Disclosure, Tab } from '@headlessui/react'
import Link from 'next/link'
import {
  hamburgerMenuClick,
  hamburgerMenuClickLevel2,
  hamburgerMenuClickLevel3,
} from './eventsHelper'
import { APP_LINK_HAMBURGER } from '@components/utils/constants'
import { OrangePhoneIcon } from '@components/icons'

interface IProps {
  config: Array<any>
  handleClick: () => void
  setIsOpen: (val: boolean) => void
  isOpen: boolean
  user: any
  hydrationState: boolean
  setAppliedFilters: (val: Array<any>) => void
  isGuestUser: boolean
  setSidebarView: (val: string) => void
  asPath: string
}

const NavbarTabView = (props: IProps) => {
  const {
    config,
    handleClick,
    setIsOpen,
    isOpen,
    user,
    hydrationState,
    setAppliedFilters,
    isGuestUser,
    setSidebarView,
    asPath,
  } = props

  return (
    <Tab.Group
      as="div"
      className="w-full border-b border-gray-200 tab-group mob-scroll-sec mob-padding-bottom"
    >
      {config
        ?.sort((a: { displayOrder: number }, b: { displayOrder: number }) =>
          a.displayOrder > b.displayOrder ? 1 : -1
        )
        .map((item: any, idxItem: number) => {
          return (
            <>
              {!item.navBlocks.length ? (
                <Link
                  legacyBehavior
                  href={
                    item?.isDownloadBtnLink
                      ? item?.hyperlink
                      : `/${removePrecedingSlash(item.hyperlink)}`
                  }
                  passHref
                  key={`navbar-${idxItem}-nav-tab-group-mobile-${item.caption.replace(
                    ' ',
                    '-'
                  )}`}
                >
                  <a
                    className={`navbar-${idxItem}-nav-tab-group-mobile-${item.caption.replace(
                      ' ',
                      '-'
                    )} flex justify-between items-center px-4 py-6 mb-1 text-xl font-semibold capitalize nav-border text-gray-dark whitespace-nowrap dark:text-black nav-dropdown-bg ${
                      item?.className ? item?.className : ''
                    }`}
                    onClick={() => {
                      handleClick()
                      setIsOpen(!isOpen)
                      hamburgerMenuClick(item.caption, user)
                    }}
                    target={item?.isDownloadBtnLink ? '_blank' : undefined}
                  >
                    <span>{item.caption}</span>
                    {item?.icon && <span>{item?.icon}</span>}
                  </a>
                </Link>
              ) : (
                <>
                  {hydrationState && (
                    <Disclosure>
                      {({ open }) => (
                        <>
                          <Disclosure.Button
                            key={`navbar-${idxItem}-nav-tab-group-new`}
                            className="relative flex flex-col w-full px-4 py-6 pr-8 mb-1 text-xl font-semibold capitalize border-b nav-dropdown-bg text-gray-dark dark:text-black whitespace-nowrap"
                            onClick={() =>
                              hamburgerMenuClick(item.caption, user)
                            }
                          >
                            <span>{item.caption}</span>
                            <span
                              className={`${
                                open ? 'hidden' : ''
                              } w-5 text-black absolute top-2/4 right-3 -translate-y-2/4 leading-none line-height-0`}
                            >
                              <i className="sprite-icon sprite-nav-plus"></i>
                            </span>
                            <span
                              className={`${
                                open ? 'inline-block' : 'hidden'
                              } w-5 text-black absolute top-2/4 right-3 -translate-y-2/4 leading-none line-height-0`}
                            >
                              <i className="sprite-icon sprite-nav-minus"></i>
                            </span>
                          </Disclosure.Button>
                          <Disclosure.Panel className="pt-1 pb-1 text-sm text-gray-500">
                            <div className="space-y-4">
                              {item.navBlocks.length ? (
                                <div className="relative bg-white">
                                  <div className="px-0 mx-auto max-w-7xl sm:px-0 lg:px-0">
                                    <div className="grid items-start grid-cols-1 md:grid-cols-1 lg:gap-x-8">
                                      {hydrationState &&
                                        item.navBlocks.map(
                                          (navBlock: any, navIdx: number) => {
                                            return (
                                              <div
                                                key={`navbar-${navIdx}-nav-box-title-mobile`}
                                                className="grid grid-cols-1 gap-y-0 gap-x-0 lg:gap-x-0 nav-inner-li"
                                              >
                                                <Disclosure>
                                                  {({ open }) => (
                                                    <>
                                                      <Disclosure.Button
                                                        className="relative flex flex-col px-4 py-3 pr-8 my-1 text-sm font-semibold capitalize bg-white border-b navlink-inner text-primary whitespace-nowrap"
                                                        onClick={() =>
                                                          hamburgerMenuClickLevel2(
                                                            item.caption,
                                                            navBlock.boxTitle,
                                                            user
                                                          )
                                                        }
                                                      >
                                                        <span>
                                                          {navBlock.boxTitle}
                                                        </span>
                                                        <span
                                                          className={`${
                                                            open ? 'hidden' : ''
                                                          } w-5 text-black absolute top-2/4 right-3 -translate-y-2/4 leading-none line-height-0`}
                                                        >
                                                          <i className="sprite-icon sprite-nav-plus"></i>
                                                        </span>
                                                        <span
                                                          className={`${
                                                            open
                                                              ? 'inline-block'
                                                              : 'hidden'
                                                          } w-5 text-black absolute top-2/4 right-3 -translate-y-2/4 leading-none line-height-0`}
                                                        >
                                                          <i className="sprite-icon sprite-nav-minus"></i>
                                                        </span>
                                                      </Disclosure.Button>
                                                      <Disclosure.Panel className="px-4 pr-2 text-sm text-gray-500">
                                                        <div className="px-0 py-1 pt-2 mt-1 border-gray-100 sm:grid sm:grid-cols-1 sm:gap-x-6">
                                                          <ul
                                                            role="list"
                                                            aria-labelledby="clothing-heading"
                                                            className="grid grid-cols-1 mob-left-padding"
                                                          >
                                                            {navBlock.navItems.map(
                                                              (
                                                                navItem: any,
                                                                navItemIdx: number
                                                              ) =>
                                                                navItem?.navBlockType !=
                                                                9 ? (
                                                                  <li
                                                                    className="flex"
                                                                    key={`navbar-${navItemIdx}-nav-items-mobile`}
                                                                  >
                                                                    <Link
                                                                      legacyBehavior
                                                                      href={`/men/${removePrecedingSlash(
                                                                        navItem.itemLink
                                                                      )}`}
                                                                      passHref
                                                                    >
                                                                      <a
                                                                        onClick={() => {
                                                                          setAppliedFilters(
                                                                            []
                                                                          )
                                                                          handleClick()
                                                                          setIsOpen(
                                                                            !isOpen
                                                                          )
                                                                          hamburgerMenuClickLevel3(
                                                                            item.caption,
                                                                            navBlock.boxTitle,
                                                                            navItem.caption,
                                                                            user
                                                                          )
                                                                        }}
                                                                        className="relative w-full py-3 my-1 text-sm hover:text-gray-800 nav-border text-gray-dark"
                                                                      >
                                                                        {
                                                                          navItem.caption
                                                                        }
                                                                        <span className="absolute w-5 leading-none text-black top-2/4 right-2 -translate-y-2/4">
                                                                          <i className="sprite-icon sprite-nav-right-arrow"></i>
                                                                        </span>
                                                                      </a>
                                                                    </Link>
                                                                  </li>
                                                                ) : (
                                                                  <li
                                                                    key={`navbar-${navItemIdx}-nav-items-new-child-mobile`}
                                                                    className="flex"
                                                                  >
                                                                    <Link
                                                                      legacyBehavior
                                                                      href={`/men/${removePrecedingSlash(
                                                                        navItem.itemLink
                                                                      )}`}
                                                                      passHref
                                                                    >
                                                                      <a
                                                                        onClick={() => {
                                                                          handleClick()
                                                                          setIsOpen(
                                                                            !isOpen
                                                                          )
                                                                          hamburgerMenuClickLevel3(
                                                                            item.caption,
                                                                            navBlock.boxTitle,
                                                                            navItem.caption,
                                                                            user
                                                                          )
                                                                        }}
                                                                        className="relative w-full py-3 my-1 text-sm hover:text-gray-800 nav-border text-gray-dark"
                                                                      >
                                                                        {
                                                                          navItem.caption
                                                                        }
                                                                        <span className="absolute w-5 leading-none text-black top-2/4 right-2 -translate-y-2/4">
                                                                          <i className="sprite-icon sprite-nav-right-arrow" />
                                                                        </span>
                                                                      </a>
                                                                    </Link>
                                                                  </li>
                                                                )
                                                            )}
                                                          </ul>
                                                        </div>
                                                      </Disclosure.Panel>
                                                    </>
                                                  )}
                                                </Disclosure>
                                              </div>
                                            )
                                          }
                                        )}
                                    </div>
                                  </div>
                                </div>
                              ) : null}
                            </div>
                          </Disclosure.Panel>
                        </>
                      )}
                    </Disclosure>
                  )}
                </>
              )}
            </>
          )
        })}

      <div className="block w-full sm:hidden">
        <Link legacyBehavior href={APP_LINK_HAMBURGER} passHref>
          <a
            onClick={() => {
              setIsOpen(!isOpen)
              handleClick()
            }}
            className="relative flex flex-col px-4 py-6 pr-10 text-xl font-semibold capitalize nav-border text-gray-dark whitespace-nowrap dark:text-black"
          >
            <span>Download The Mobile App</span>
            <span className="absolute leading-none top-2/4 right-3 -translate-y-2/4">
              <OrangePhoneIcon />
            </span>
          </a>
        </Link>
        <Link legacyBehavior href="/stores" passHref>
          <a
            onClick={() => {
              setIsOpen(!isOpen)
              handleClick()
            }}
            className="relative flex flex-col px-4 py-6 pr-10 text-xl font-semibold capitalize nav-border text-gray-dark whitespace-nowrap dark:text-black"
          >
            <span>Store Locator</span>
          </a>
        </Link>
        {hydrationState && !isGuestUser && user?.userId ? (
          <Link legacyBehavior href="/my-account" passHref>
            <a
              onClick={() => {
                setIsOpen(!isOpen)
                handleClick()
              }}
              className="relative flex flex-col px-4 py-6 pr-10 text-sm font-semibold capitalize nav-border text-gray-dark whitespace-nowrap dark:text-black"
            >
              Track Order
              <span className="absolute leading-none top-2/4 right-3 -translate-y-2/4">
                <i className="sprite-icon sprite-track-icon"></i>
              </span>
            </a>
          </Link>
        ) : (
          <a
            onClick={() => {
              handleClick()
              setIsOpen(!isOpen)
              openLoginView(asPath)
              setSidebarView('LOGIN_VIEW')
            }}
            className="relative flex flex-col px-4 py-6 pr-10 text-sm font-semibold capitalize nav-border text-gray-dark whitespace-nowrap dark:text-black"
          >
            <span>Track Order</span>
            <span className="absolute leading-none top-2/4 right-3 -translate-y-2/4">
              <i className="sprite-icon sprite-track-icon"></i>
            </span>
          </a>
        )}
        <Link legacyBehavior href="/faq" passHref>
          <a
            onClick={() => {
              setIsOpen(!isOpen)
              handleClick()
            }}
            className="relative flex flex-col px-4 py-6 pr-10 text-sm font-semibold capitalize nav-border text-gray-dark whitespace-nowrap dark:text-black"
          >
            <span>Help & FAQs</span>
            <span className="absolute leading-none top-2/4 right-3 -translate-y-2/4">
              <i className="sprite-icon sprite-help-icon"></i>
            </span>
          </a>
        </Link>
        <Link legacyBehavior href="/blog" passHref>
          <a
            onClick={() => {
              setIsOpen(!isOpen)
              handleClick()
            }}
            className="relative flex flex-col px-4 py-6 pr-10 text-sm font-semibold capitalize nav-border text-gray-dark whitespace-nowrap dark:text-black"
          >
            <span>Blog</span>
            <span className="absolute leading-none top-2/4 right-3 -translate-y-2/4">
              <i className="sprite-icon sprite-blog-icon"></i>
            </span>
          </a>
        </Link>
        <Link legacyBehavior href="/about-us" passHref>
          <a
            onClick={() => {
              setIsOpen(!isOpen)
              handleClick()
            }}
            className="relative flex flex-col px-4 py-6 pr-10 text-sm font-semibold capitalize nav-border text-gray-dark whitespace-nowrap dark:text-black"
          >
            <span>About Us</span>
            <span className="absolute leading-none top-2/4 right-3 -translate-y-2/4">
              <i className="sprite-icon sprite-da-icon"></i>
            </span>
          </a>
        </Link>
      </div>
    </Tab.Group>
  )
}

export default NavbarTabView

import { recordGA4Event } from '@components/services/analytics/ga4'
import { EmptyGuid } from '@components/utils/constants'
import { getCurrentPage } from '@framework/utils/app-util'
import { GA_EVENT } from 'hooks/ga_event.modal'
import { isMobileOnly } from 'react-device-detect'

const currentPage = getCurrentPage() || ''

const deviceCheck = isMobileOnly ? 'Mobile' : 'Desktop'

export const viewWishlist = (user: any) => {
  if (currentPage) {
    recordGA4Event(window, GA_EVENT.WISHLIST, {
      ecommerce: {
        header: 'Menu Bar',
        current_page: currentPage,
        loggedin_status: !!(user?.userId && user?.userId !== EmptyGuid),
      },
    })
  }
}

export const hamburgerMenu = (user: any) => {
  if (currentPage) {
    recordGA4Event(window, GA_EVENT.HAMBURGER_MENU, {
      current_page: currentPage,
      device: deviceCheck,
      loggedin_status: !!(user?.userId && user?.userId !== EmptyGuid),
    })
    recordGA4Event(window, GA_EVENT.HAMBURGER_ICON_CLICK, {
      header: 'Menu',
      sub_header: '',
      sub_header2: '',
      current_page: currentPage,
      device: deviceCheck,
      loggedin_status: !!(user?.userId && user?.userId !== EmptyGuid),
    })
  }
}

export const hamburgerMenuClick = (item: any, user: any) => {
  if (currentPage) {
    recordGA4Event(window, GA_EVENT.HAMBURGER_MENU_CLICK, {
      header: item,
      sub_header: '',
      sub_header2: '',
      current_page: currentPage,
      device: deviceCheck,
      loggedin_status: !!(user?.userId && user?.userId !== EmptyGuid),
    })
  }
}

export const hamburgerMenuClickLevel2 = (
  item: any,
  subHeader: any,
  user: any
) => {
  if (currentPage) {
    recordGA4Event(window, GA_EVENT.HAMBURGER_MENU_CLICK, {
      header: item,
      sub_header: subHeader,
      sub_header2: '',
      current_page: currentPage,
      device: deviceCheck,
      loggedin_status: !!(user?.userId && user?.userId !== EmptyGuid),
    })
  }
}

export const hamburgerMenuClickLevel3 = (
  item: any,
  subHeader: any,
  subHeader2: any,
  user: any
) => {
  if (currentPage) {
    recordGA4Event(window, GA_EVENT.HAMBURGER_MENU_CLICK, {
      header: item,
      sub_header: subHeader,
      sub_header2: subHeader2,
      current_page: currentPage,
      device: deviceCheck,
      loggedin_status: !!(user?.userId && user?.userId !== EmptyGuid),
    })
  }
}

export const navMenuLevel3GAEvent = (
  item: any,
  subHeader: any,
  subHeader2: any,
  previousPath: string
) => {
  if (typeof window !== 'undefined') {
    recordGA4Event(window, 'nav_menu', {
      category1: item,
      category2: subHeader,
      category3: subHeader2,
      previous_page: previousPath,
    })
  }
}

export const navMenuLevel1GAEvent = (item: any, previousPath: string) => {
  if (typeof window !== 'undefined') {
    recordGA4Event(window, 'nav_menu', {
      category1: item,
      category2: '',
      category3: '',
      previous_page: previousPath,
    })
  }
}

export const userProfileGAEvent = (previousPath: string) => {
  if (typeof window !== 'undefined') {
    recordGA4Event(window, 'user_profile', {
      previous_page: previousPath,
    })
  }
}

export const closeHamburgerMenuGAEvent = (previousPath: string) => {
  if (typeof window !== 'undefined') {
    recordGA4Event(window, 'close_button', {
      current_page: currentPage,
      previous_page: previousPath,
    })
  }
}

import { openLoginView, removePrecedingSlash } from '@framework/utils/app-util'
import { Popover, Transition } from '@headlessui/react'
import classNames from 'classnames'
import Link from 'next/link'
import { navMenuLevel1GAEvent, navMenuLevel3GAEvent } from './eventsHelper'
import { Fragment } from 'react'

interface IProps {
  hydrationState: boolean
  config: Array<any>
  setOpenState: (val: number) => void
  openState: number
  previousPath: string
  setAppliedFilters: (val: Array<any>) => void
  setIsOpen: (val: boolean) => void
  isOpen: boolean
  user: any
  isGuestUser: boolean
  setSidebarView: (val: string) => void
  asPath: string
}

const NavbarMenu = (props: IProps) => {
  const {
    hydrationState,
    config,
    setOpenState,
    openState,
    previousPath,
    setAppliedFilters,
    setIsOpen,
    isOpen,
    user,
    isGuestUser,
    setSidebarView,
    asPath,
  } = props

  return (
    <Popover.Group className="absolute inset-x-0 bottom-0 hidden w-full sm:static sm:self-stretch lg:block sm:h-20">
      <div className="flex px-4 pb-px space-x-8 overflow-x-auto border-t h- sm:h-full sm:border-t-0 sm:justify-center sm:overflow-visible sm:pb-0">
        {hydrationState &&
          config.map((item: any, idxConfig: number) => {
            return (
              <Popover
                key={`navbar-${idxConfig}-navbar-popover`}
                className="flex"
                onMouseEnter={() => setOpenState(idxConfig)}
                onMouseLeave={() => setOpenState(-1)}
              >
                {({ open }) => (
                  <>
                    {hydrationState && !item.navBlocks.length ? (
                      <Link
                        legacyBehavior
                        href={`/${removePrecedingSlash(item.hyperlink)}`}
                        passHref
                        className="relative flex"
                      >
                        <a
                          className={classNames(
                            idxConfig && openState == idxConfig
                              ? 'border-black text-black'
                              : 'border-transparent text-brown-light dark:text-gray-700 hover:text-gray-800',
                            'relative z-10 flex items-center sm:h-20 transition-colors ease-out duration-200 text-xs font-medium border-b-2 -mb-px pt-px'
                          )}
                          onClick={() =>
                            navMenuLevel1GAEvent(item.caption, previousPath)
                          }
                        >
                          {item.caption}
                        </a>
                      </Link>
                    ) : (
                      <span
                        className={classNames(
                          idxConfig && openState == idxConfig
                            ? 'border-black text-black'
                            : 'border-transparent text-brown-light text-gray-700 dark:text-black hover:text-gray-800',
                          'relative z-10 cursor-pointer flex items-center sm:h-20 transition-colors ease-out duration-200 text-xs font-medium border-b-2 -mb-px pt-px'
                        )}
                        onClick={() =>
                          navMenuLevel1GAEvent(item.caption, previousPath)
                        }
                      >
                        {item.caption}
                      </span>
                    )}
                    <Transition
                      show={
                        item.navBlocks && item.navBlocks.length
                          ? openState == idxConfig
                          : false
                      }
                      as={Fragment}
                      enterTo="opacity-100"
                      leaveTo="opacity-100"
                    >
                      <Popover.Panel className="absolute inset-x-0 z-50 text-gray-500 top-full sm:text-sm">
                        <div
                          onMouseEnter={() => setOpenState(-1)}
                          className="hidden popover-overlay sm:block"
                        ></div>
                        <div className="relative mx-auto bg-white shadow nav-container">
                          <div className="w-full px-4 mx-auto border-t sm:px-6 lg:px-8">
                            <div className="flex justify-between">
                              {item.navBlocks &&
                                item.navBlocks
                                  .sort(
                                    (
                                      a: {
                                        displayOrder: number
                                      },
                                      b: {
                                        displayOrder: number
                                      }
                                    ) =>
                                      a.displayOrder > b.displayOrder ? 1 : -1
                                  )
                                  .map((navBlock: any, navIdx: number) => {
                                    return (
                                      <div
                                        key={`navbar-${navIdx}-nav-block`}
                                        className="w-full px-4 py-6"
                                      >
                                        <div>
                                          <p className="mb-2 text-sm font-semibold text-gray-900 capitalize">
                                            {navBlock.boxTitle}
                                          </p>
                                          <div className="w-full">
                                            <ul
                                              role="list"
                                              aria-labelledby="clothing-heading"
                                              className="w-full"
                                            >
                                              {navBlock.navItems
                                                .sort(
                                                  (
                                                    a: {
                                                      displayOrder: number
                                                    },
                                                    b: {
                                                      displayOrder: number
                                                    }
                                                  ) =>
                                                    a.displayOrder >
                                                    b.displayOrder
                                                      ? 1
                                                      : -1
                                                )
                                                .map(
                                                  (
                                                    navItem: any,
                                                    navItemIdx: number
                                                  ) =>
                                                    navItem?.navBlockType !=
                                                    9 ? (
                                                      <li
                                                        key={`navbar-${navItem.caption}-${navItemIdx}-nav-child`}
                                                        className="flex"
                                                      >
                                                        <Link
                                                          legacyBehavior
                                                          href={`/men/${removePrecedingSlash(
                                                            navItem.itemLink
                                                          )}`}
                                                          passHref
                                                        >
                                                          <a
                                                            onClick={() => {
                                                              setAppliedFilters(
                                                                []
                                                              )
                                                              setOpenState(-1)
                                                              setIsOpen(!isOpen)
                                                              navMenuLevel3GAEvent(
                                                                item.caption,
                                                                navBlock.boxTitle,
                                                                navItem.caption,
                                                                previousPath
                                                              )
                                                            }}
                                                            className="relative inline-block w-full py-4 pr-8 text-left border-b dark:text-black hover:text-gray-800 hover:border-gray-900"
                                                          >
                                                            <Popover.Button
                                                              className={classNames(
                                                                idxConfig &&
                                                                  openState ==
                                                                    idxConfig
                                                                  ? ''
                                                                  : 'border-transparent text-gray-dark capitalize dark:text-black hover:text-gray-800',
                                                                'relative z-10 flex transition-colors ease-out duration-200 text-sm -mb-px pt-px text-left'
                                                              )}
                                                            >
                                                              {navItem.caption}
                                                            </Popover.Button>
                                                            <span className="absolute right-0 top-2/4 -translate-y-2/4">
                                                              <i className="sprite-icon sprite-nav-right-arrow"></i>
                                                            </span>
                                                          </a>
                                                        </Link>
                                                      </li>
                                                    ) : (
                                                      <li
                                                        key={`navbar-${navItemIdx}-nav-child-or`}
                                                        className="flex"
                                                      >
                                                        <Link
                                                          href={`${removePrecedingSlash(
                                                            navItem.itemLink
                                                          )}`}
                                                          passHref
                                                          legacyBehavior
                                                        >
                                                          <a
                                                            onClick={() =>
                                                              setOpenState(-1)
                                                            }
                                                            className="relative inline-block w-full py-4 pr-8 border-b dark:text-black hover:text-gray-800 hover:border-gray-900"
                                                          >
                                                            <Popover.Button
                                                              className={classNames(
                                                                idxConfig &&
                                                                  openState ==
                                                                    idxConfig
                                                                  ? ''
                                                                  : 'border-transparent text-gray-dark capitalize hover:text-gray-800 dark:text-black',
                                                                'relative z-10 flex items-center transition-colors ease-out duration-200 text-sm -mb-px pt-px'
                                                              )}
                                                            >
                                                              {navItem.caption}
                                                            </Popover.Button>
                                                            <span className="absolute right-0 top-2/4 -translate-y-2/4">
                                                              <i className="sprite-icon sprite-nav-right-arrow"></i>
                                                            </span>
                                                          </a>
                                                        </Link>
                                                      </li>
                                                    )
                                                )}
                                              {navBlock.contentBody != null ||
                                              navBlock.contentBody != '' ? (
                                                <li>
                                                  <div
                                                    className="mt-6"
                                                    dangerouslySetInnerHTML={{
                                                      __html:
                                                        navBlock.contentBody,
                                                    }}
                                                  ></div>
                                                </li>
                                              ) : (
                                                <></>
                                              )}
                                            </ul>
                                          </div>
                                        </div>
                                      </div>
                                    )
                                  })}
                            </div>
                          </div>
                        </div>
                      </Popover.Panel>
                    </Transition>
                  </>
                )}
              </Popover>
            )
          })}
        <div className="flex">
          {hydrationState && !isGuestUser && user?.userId ? (
            <Link legacyBehavior href="/my-account/orders" passHref>
              <a
                className="relative z-10 flex items-center pt-px -mb-px text-xs font-medium transition-colors duration-200 ease-out border-b-2 border-transparent hover:border-black hover:border-b text-brown-light dark:text-gray-700 hover:text-gray-800 sm:h-20"
                onClick={() =>
                  navMenuLevel1GAEvent('Track Order', previousPath)
                }
              >
                Track Order
              </a>
            </Link>
          ) : (
            <div
              onClick={() => {
                openLoginView(asPath)
                setSidebarView('LOGIN_VIEW')
              }}
              className="cursor-pointer"
            >
              <span className="relative z-10 flex items-center pt-px -mb-px text-xs font-medium transition-colors duration-200 ease-out border-b-2 border-transparent hover:border-black hover:border-b text-brown-light dark:text-gray-700 hover:text-gray-800 sm:h-20">
                Track Order
              </span>
            </div>
          )}
        </div>
        <div className="flex">
          <Link legacyBehavior href="/stores" passHref>
            <a
              className="relative z-10 flex items-center pt-px -mb-px text-xs font-medium transition-colors duration-200 ease-out border-b-2 border-transparent hover:border-black hover:border-b text-brown-light dark:text-gray-700 hover:text-gray-800 sm:h-20"
              onClick={() =>
                navMenuLevel1GAEvent('Store Locator', previousPath)
              }
            >
              Store Locator
            </a>
          </Link>
        </div>
      </div>
    </Popover.Group>
  )
}

export default NavbarMenu

import * as React from 'react'
import { Skeleton } from '@components/ui'
import { NavbarMobileSkeleton } from './navbarMobile.skeleton'

interface IProps {
  height?: string
  width?: string
  isMobile?: boolean
}

export const NavbarSkeleton: React.FunctionComponent<IProps> = ({
  height,
  width,
  isMobile,
}) => {
  return (
    <>
      <div className="mobile-tab">
        <NavbarMobileSkeleton />
      </div>
      <div className="desktop-laptop mt-[20px] mb-[20px]">
        <header className={`relative top-0 w-full`}>
          <div className="animate-pulse flex items-center gap-x-2">
            <Skeleton
              width={117}
              height={40}
              style={{
                borderRadius: '5px',
                background: 'rgba(0, 0, 0, 0.11)',
              }}
            />

            <Skeleton
              height="12px"
              width="5%"
              style={{
                width: '5%',
                marginLeft: '3%',
                borderRadius: '5px',
                background: 'rgba(0, 0, 0, 0.11)',
              }}
            />

            <Skeleton
              height="12px"
              width="5%"
              style={{
                width: '5%',
                marginLeft: '3%',
                borderRadius: '5px',
                background: 'rgba(0, 0, 0, 0.11)',
              }}
            />

            <Skeleton
              height="12px"
              width="5%"
              style={{
                width: '5%',
                marginLeft: '3%',
                borderRadius: '5px',
                background: 'rgba(0, 0, 0, 0.11)',
              }}
            />

            <Skeleton
              height="12px"
              width="5%"
              style={{
                width: '5%',
                marginLeft: '3%',
                borderRadius: '5px',
                background: 'rgba(0, 0, 0, 0.11)',
              }}
            />

            <Skeleton
              height="12px"
              width="5%"
              style={{
                width: '5%',
                marginLeft: '3%',
                borderRadius: '5px',
                background: 'rgba(0, 0, 0, 0.11)',
              }}
            />

            <Skeleton
              height="12px"
              width="5%"
              style={{
                width: '5%',
                marginLeft: '3%',
                borderRadius: '5px',
                background: 'rgba(0, 0, 0, 0.11)',
              }}
            />

            <Skeleton
              height="12px"
              width="5%"
              style={{
                width: '5%',
                marginLeft: '3%',
                borderRadius: '5px',
                background: 'rgba(0, 0, 0, 0.11)',
              }}
            />

            <Skeleton
              height="12px"
              width="5%"
              style={{
                width: '5%',
                marginLeft: '3%',
                borderRadius: '5px',
                background: 'rgba(0, 0, 0, 0.11)',
              }}
            />

            <Skeleton
              width={25}
              height={25}
              style={{
                marginLeft: '35px',
                borderRadius: '5px',
                background: 'rgba(0, 0, 0, 0.11)',
              }}
            />

            <Skeleton
              width={25}
              height={25}
              style={{
                marginLeft: '15px',
                borderRadius: '5px',
                background: 'rgba(0, 0, 0, 0.11)',
              }}
            />

            <Skeleton
              width={25}
              height={25}
              style={{
                marginLeft: '15px',
                borderRadius: '5px',
                background: 'rgba(0, 0, 0, 0.11)',
              }}
            />
          </div>
        </header>
      </div>
    </>
  )
}
